@import "@scrippsproduct/entertainment-sites-css/src/header.scss";

header logo-navigation {
    --transition-length: 0.275s;

    a {
        display: block;

        &:before {
            content: "";
            position: absolute;
            bottom: -0.25em;
            left: 0;
            margin: 0 auto;
            width: 100%;
            height: 0.05rem;
            background: var(--theme-color-400);
            box-shadow: 2px 2px 4px rgba(0,0,0, 0.3);
            opacity: 0;
            transform: scale(1) translateY(0.5rem);
    
            transition: transform var(--transition-length) ease-out;
        }
    
        &:hover,
        &:focus {
            scale: 1.05;
            filter: drop-shadow(2px, 2px, 2px, rgba(0,0,0,0.8));
            color: var(--theme-color-400);
    
            &:before {
                transform: scale(1) translateY(0);
                opacity: 1;
            }
        }
    }

    img {
        filter: grayscale(1) brightness(0) invert(1);
        transition: filter 0.325s ease-out;
    }

    &:hover {
        img {
            filter: none;
        }
    }
}

.main-navigation {
    margin: 0 2rem 0 auto;
}

.main-navigation__nav-link {
    --transition-length: 0.275s;    
    font-family: 'Fairplex', sans-serif;
    font-weight: 700;
    font-size: 1.25rem;

    &:before {
        height: 0.05rem;
        background: var(--theme-color-400);
        opacity: 0;
        transform: scale(1) translateY(0.5rem);

        transition: transform var(--transition-length) ease-out;
    }

    &:hover,
    &:focus {
        filter: drop-shadow(2px, 2px, 2px, rgba(0,0,0,0.8));
        color: var(--theme-color-400);
        text-shadow: unset;

        &:before {
            transform: scale(1) translateY(0);
            opacity: 1;
        }
    }
}

@media screen and (max-width: 60rem) {
    .drawer-navigation__nav-bar .image-replacement-anchor {
        --ira-height: 2.5rem !important;
        --ira-baseline-adjustment: 0.1rem !important;
    } 
}