@import "@scrippsproduct/entertainment-sites-css/src/footer.scss";

.main-footer {
    background-image:  var(--footer-gradient), var(--body-background-url);
    background-position: bottom;  
    background-attachment: local, fixed;
    background-color: transparent;
    border-block-start: 1px solid var(--theme-color-400);


}

.info-page + .main-footer {
    background-image: var(--footer-gradient), var(--body-background-url);
}

.footer-logo {
    margin-block-start: 4rem;
}

.footer-nav-wrapper {

    .social-links__header {
        font-family: "Fairplex", serif;
        font-size: 1.125rem;
        margin-block: 0 1rem;
    }
}

.footer-nav {

    .h4 {
        text-transform: none;
        font-size: 1.25rem;
        margin-block-start: 0;
    }
}
