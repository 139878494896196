// Expressway Font
@font-face {
    font-family: 'Expressway';
    font-weight: 350;
    src: url('./../fonts/expressway_book-webfont.woff2') format('woff2'),
        url('./../fonts/expressway_book-webfont.woff') format('woff');
}
@font-face {
    font-family: 'Expressway';
    font-weight: 400;
    src: url('./../fonts/expressway_regular-webfont.woff2') format('woff2'),
        url('./../fonts/expressway_regular-webfont.woff') format('woff');
}
@font-face {
    font-family: 'Expressway';
    font-weight: 600;
    src: url('./../fonts/expressway_semibold-webfont.woff2') format('woff2'),
        url('./../fonts/expressway_semibold-webfont.woff') format('woff');
}
// Fairplex font
@font-face {
    font-family: 'Fairplex';
    font-weight: 500;
    src: url('./../fonts/fairplexnarrowbook_medium-webfont.woff2') format('woff2'),
        url('./../fonts/fairplexnarrowbook_medium-webfont.woff') format('woff');
}
@font-face {
    font-family: 'Fairplex';
    font-weight: 700;
    src: url('./../fonts/fairplexnarrowbold_bold-webfont.woff2') format('woff2'),
        url('./../fonts/fairplexnarrowbold_bold-webfont.woff') format('woff');
}
// GettyRye font
@font-face {
    font-family: 'GettyRye';
    font-weight: 400;
    src: url('./../fonts/GettyRye-Regular.woff2') format('woff2'),
        url('./../fonts/GettyRye-Regular.woff') format('woff');
}
// Museo Slab Rounded font
@font-face {
    font-family: 'MuseoSlab';
    font-weight: 900;
    src: url('./../fonts/MuseoSlabRounded-900.woff2') format('woff2'),
        url('./../fonts/MuseoSlabRounded-900.woff') format('woff');
}

:root {
    /* theme colors */
    --theme-color-100: hsl(200, 27%, 6%);
    --theme-color-200: hsl(202, 79%, 19%);
    --theme-color-300: hsl(34, 98%, 47%);
    --theme-color-400: hsl(41, 99%, 63%);
    --theme-color-500: hsl(41, 61%, 94%);
    /* monotones */
    --mono-color-100: hsl(0, 0%, 100%);
    --mono-color-200: hsla(0, 0%, 0%, 0.169);
    --mono-color-300: hsla(0, 0%, 0%, 0.2);
    --mono-color-400: hsla(0, 0%, 0%, 0.251);
    --mono-color-500: hsla(0, 0%, 0%, 0.404);
    --mono-color-600: hsla(0, 0%, 0%, 0.596);
    --mono-color-700: hsla(0, 0%, 0%, 0.604);
    --mono-color-800: hsla(0, 0%, 0%, 0.749);
    --mono-color-900: hsl(0, 0%, 0%);
    /* form colors */
    --form-valid-color: hsl(150, 55%, 38%);
    --form-invalid-color: hsl(0, 51%, 50%);
    /* gradients */
    --body-gradient: linear-gradient(to bottom, var(--theme-color-100) 0%, var(--theme-color-100) 100%);
    --footer-gradient: linear-gradient(to bottom, hsl(202deg 70.42% 16.13%), transparent);
    --menu-gradient: linear-gradient(90deg, hsl(39, 100%, 41%) 0%, hsl(30, 82%, 58%) 50%, hsl(28, 82%, 55%) 90%);
    --on-now-gradient: linear-gradient(to bottom, var(--theme-color-400) 0%, var(--theme-color-400) 100%);
    /* misc */
    --cta-button-color: var(--theme-color-300);  
    --on-now-text-color: var(--theme-color-300);
    --on-now-button-color: var(--theme-color-300);
    /* body background */
    --body-background-url: url('./../images/grit-body-bkgd.jpg');
    --body-background-blend-mode: normal;
    --body-background-repeat: no-repeat;
    --body-background-size: cover;
    --body-background-position: top center;
    --body-background-attachment: fixed;

    --main-font-family: 'Open Sans', sans-serif;
    --paragraph-font-family: 'Open Sans', sans-serif;
    
    --anchor-color: var(--theme-color-400);
    --anchor-hover-color: var(--mono-color-100);    
    --inline-anchor-color: var(--theme-color-400);
    
    --headers-color: var(--mono-color-100);
    
    --error-handler-background-color:  var(--mono-color-800);
    --error-handler-outline-color: var(--theme-color-300);
    
    --modals-close-btn-color: var(--mono-color-100);
    --modals-close-btn-outline-color: var(--theme-color-300);
}