@import "@scrippsproduct/entertainment-sites-css/src/page-not-found.scss";

.page-not-found {
    color: var(--mono-color-100);
}

.page-not-found__link {
    > a {
        color: var(--theme-color-400);

        &:hover {
            color: var(--theme-color-200);
        }
    }
}

.page-not-found__section {
    background-color: transparent;
}

.page-not-found__header {
    margin-block-start: 0;
}