@import 'Settings';
@import "@scrippsproduct/entertainment-sites-css/src/base.scss";

body {
    background-image: var(--body-background-url);
    color: var(--mono-color-100);
}
/* 
   This is here to prevent a strange rendering bug that was happening on the schedule page in Chrome v125.0.6422.77
   When site-wrapper set to anything other than 'display: unset', the main nav menu would shift up and to the left
   when any of it's links were hovered over. Althoug in the inspector, the element's position was not changing.
*/
site-wrapper:has(#schedule-section) {
    display: unset;
}

#schedule-section {
    min-height: 100vh;
    min-height: 100dvh;
}
/*=================================================================================================================*/

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: 'Fairplex', serif;
    font-weight: 700;
}

.h3 {
    font-weight: 500;
}

content-constrainer {
    border-bottom: 1px solid var(--mono-color-100);
}

loading-wrapper {
    --spinner-color: var(--theme-color-400) !important;

    > div > div {
        border-bottom: var(--spinner-track-width) solid rgba(255, 255, 255, 0.2) !important;
        border-left: var(--spinner-track-width) solid rgba(255, 255, 255, 0.2) !important;
        border-right: var(--spinner-track-width) solid rgba(255, 255, 255, 0.2) !important;
    }
}

.main-content.info-page > content-constrainer:first-child {
    border: 1px solid;
    background-color: var(--mono-color-700);
    margin-block-end: 3rem;
}

.page-section {
    background-color: transparent;
}

.page-content-header {
    font-family: 'GettyRye', sans-serif;
    font-size: clamp(1.8rem, 4.5vw + 1rem, 5rem);
    font-weight: 400;
    letter-spacing: -0.34375rem;
    color: var(--theme-color-400);
    line-height: 1;
}
// 500px
@media screen and (max-width: 31.15rem) {
    .page-content-header {
        font-size: clamp(3rem, 4.5vw + 1rem, 5rem);
        line-height: 1.2;
        text-wrap: balance;
        letter-spacing: -0.2rem;
    }

    .main-content.info-page > content-constrainer:first-child {
        border-left: none;
        border-right: none;
    }
}
// 400px
@media screen and (max-width: 25rem) {
    .page-content-header {
        font-size: clamp(2.8rem, 4vw + 1rem, 2.875rem);
        line-height: 1.2;
        text-wrap: balance;
    }
}

.info-page > content-constrainer {
    padding: 2.5rem;
    background-color: unset;
    border: 1px solid var(--mono-color-100);
}

.image-card__cta {
    transition: scale 0.225s ease-out, color 0.225s ease-out;
}

.icon-list-item {
    background-color: var(--theme-color-200);
    transition: background-color 0.625s ease-out;

    &:hover {
        background-color: var(--theme-color-400);
    } 
}

.icon-list-item img {
    filter: grayscale(1) brightness(0) invert(1);
    transition: transform .425s ease-in-out, filter 0.325s ease-in-out;
}

.icon-list-item:has(.icon-list-item__link) {
    transform: scale(1)!important;
}

.icon-list-item:focus img, .icon-list-item:hover img {
    filter: grayscale(1) brightness(0) invert(0);
    transform: scale(1.2);
}

.modal__close-btn {
    left: unset;
    top: -3.2rem;
    right: -0.7rem;
    transform-origin: right;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-size: 1.125rem;

    &:hover,
    &:focus,
    &:focus-within {
        transform: scale(1.1);
        border-radius: 0;
    }

    > .sr-only {
        clip: auto!important;
        border: 0!important;
        -webkit-clip-path: initial!important;
        clip-path: none!important;
        font-family: 'Fairplex';
        font-size: 1.25rem;
        font-weight: 500;
        height: auto!important;
        left: auto;
        margin: initial!important;
        overflow: visible!important;
        padding: 0!important;
        position: static!important;
        right: 2.7rem;
        text-transform: uppercase;
        top: .8rem;
        white-space: nowrap!important;
        width: auto!important;
    }

    svg {
        width: 28px;
        stroke-width: 2px;
    }
}

featured-list .image-card__content {
    color: var(--mono-color-100);

    @media screen and (max-width: 48rem) {
        font-size: clamp(1rem, 2.5vw, 1.5rem);  
    }

    @media screen and (max-width: 25rem) {
        font-size: clamp(1rem, 6vw, 1.5rem);
    }
}

featured-list {
    image-card {
        picture { overflow: hidden; }
    }
}

.airdate-formatter__pipe {
    font-size: 1em;
    vertical-align: baseline;
}
